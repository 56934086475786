import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginForm from "@/views/LoginForm"
import RegisterForm from "@/views/RegisterForm";
import UrlConventor from "@/views/UrlConventor";
import AiText from "@/views/AiText";

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/urlconventor',
    name: 'urlconventor',
    component: UrlConventor,
    meta: { requiresAuth: true },
  },
  {
    path: '/aitext',
    name: 'aitext',
    component: AiText,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginForm
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterForm
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
