<template>
    <v-container>
        <v-card class="chat-container" flat>
            <v-card-text>
                <v-list>
                    <v-list-item v-for="(message, index) in chatHistory" :key="index">
                        <v-list-item-content>
                            <v-card
                                    :color="message.role === 'user' ? 'blue' : 'green'"
                                    :outlined="true"
                                    class="message-card"
                                    flat
                            >
                                <v-card-text>
                                    <strong v-if="message.role === 'user'">User:</strong>
                                    <strong v-if="message.role === 'ai'">AI:</strong>
                                    <template v-if="message.role === 'ai'">
                                        <div v-html="renderMarkdown(message.content)"></div>
                                    </template>
                                    <template v-else>{{ message.content }}</template>
                                </v-card-text>
                            </v-card>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
        <v-text-field
                label="User Message"
                v-model="userMessage"
                @keyup.enter="addUserMessageAndGenerateResponse"
                solo
                flat
                clearable
                full-width
        />
        <v-progress-circular v-if="isLoading" indeterminate color="primary"></v-progress-circular>
    </v-container>
</template>

<script>
import axios from "axios";
import MarkdownIt from "markdown-it";

export default {
    data() {
        return {
            userMessage: "",
            chatHistory: [],
            isLoading: false,
            md: new MarkdownIt(),
        };
    },
    methods: {
        addUserMessageAndGenerateResponse() {
            this.addMessage();
            this.generateResponse();
        },
        addMessage() {
            if (this.userMessage) {
                this.chatHistory.push({
                    role: "user",
                    content: this.userMessage.trim(),
                });
                this.userMessage = "";
            }
        },
        generateResponse() {
            if (!this.chatHistory.length) return;

            this.isLoading = true;
            axios
                .post(`${process.env.VUE_APP_API_URL}/chat`, {
                    content: this.chatHistory.slice(-1)[0].content,
                })
                .then((response) => {
                    this.chatHistory.push({
                        role: "ai",
                        content: response.data.content,
                    });
                })
                .catch((error) => {
                    console.error("Error generating text:", error);
                    this.chatHistory.push({
                        role: "ai",
                        content: "Error generating text",
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        renderMarkdown(content) {
            return this.md.render(content);
        },
    },
};
</script>

<style scoped>
.chat-container {
    height: 70vh;
    overflow-y: scroll;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
}

.message-card {
    max-width: 80%;
    word-break: break-word;
}
</style>
