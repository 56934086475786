<template>
  <v-container>
    <v-card>
      <v-card-title>URL Converter</v-card-title>
      <v-card-text>
        <div v-if="status === 'ready'">
          <div class="input-container">
            <input
                type="file"
                class="file-input"
                @change="handleFileUpload"
            />
            <label class="file-label">Select a file</label>
          </div>
        </div>
        <div v-else-if="status === 'loading'">
          <p>Converting URLs...</p>
        </div>
        <div v-else>
          <p>Here are the converted URLs:</p>
          <v-list>
            <v-list-item v-for="url in convertedUrls" :key="url">
              <v-list-item-content>
                <v-list-item-title>{{ url }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: false,
      password: '',
      status: 'ready',
      originalUrls: [],
      convertedUrls: []
    };
  },
  methods: {
    handleFileUpload(event) {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        this.status = 'loading';
        const file = fileInput.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const text = reader.result;
          this.originalUrls = text.split('\n').map(url => url.trim());
          this.convertUrls();
        };
        reader.readAsText(file);
      }
    },

    convertUrls() {
      this.convertedUrls = this.originalUrls.map(url => {
        const domainMatch = url.match(/^https?:\/\/(?:www\.)?([^/]+)/);
        const domain = domainMatch ? domainMatch[1] : '';
        return `domain:${domain}`;
      });

      this.status = 'done';
    }
  }
};
</script>

<style scoped>
.input-container {
  position: relative;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-label {
  display: block;
  width: 100%;
  height: 50px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #eaeaea;
}
</style>
