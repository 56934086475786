<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="8" sm="12">
        <router-link to="/urlconventor" style="text-decoration: none">
          <v-card class="mb-3">
            <v-card-title class="text-center">URL Converter</v-card-title>
            <v-card-text>
              <p class="text-center">Change any URL to format: domain:url.sk</p>
            </v-card-text>
          </v-card>
        </router-link>
        <router-link to="/aitext" style="text-decoration: none;">
          <v-card class="mb-3">
            <v-card-title class="text-center">ChatGPT</v-card-title>
            <v-card-text>
              <p class="text-center">Tool based on openAI API for text generation from keywords.</p>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.router-style {
  text-decoration: none;
  color: inherit;
}

.input-container {
  position: relative;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-label {
  display: block;
  width: 100%;
  height: 50px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #eaeaea;
}
</style>
