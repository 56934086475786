<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <div class="d-flex align-center">
      <RouterLink to="/dashboard" class="router-style ml-2" style="color: white">
        <h1>IncreaSEO</h1>
      </RouterLink>
      </div>
      <v-spacer></v-spacer>
      <router-link to="/login" class="router-style ml-2" style="color: white" @click="logout">
       Login / Logout
      </router-link>
<!--      <router-link to="/login" class="router-style ml-2" style="color: white" v-else>-->
<!--        {{ isAuthenticated }}-->
<!--      </router-link>-->
<!--      <span class="mr-2 ml-2">Made by Adam</span>-->
<!--      <NavBar/>-->
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
// import NavBar from "@/NavBar";

export default {
  name: 'App',
  // components: {NavBar},
  data: () => ({
    sessionTimeout: 30 * 60 * 1000, // 30 minutes in milliseconds
    sessionTimer: null,
  }),
  mounted() {
    // Start the session timer when the component is mounted
    this.resetSessionTimer();
  },
  computed: {
      isAuthenticated() {
        return localStorage.getItem('token') ? 'Logout' : 'Login';
    },
  },
  methods: {
    resetSessionTimer() {
      if (this.sessionTimer) {
        clearTimeout(this.sessionTimer);
      }

      this.sessionTimer = setTimeout(() => {
        this.logout();
      }, this.sessionTimeout);
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('uuid');
      this.$router.push('/login');
    },
    onActivity() {
      this.resetSessionTimer();
    },
  },
  created() {
    window.addEventListener('mousemove', this.onActivity);
    window.addEventListener('keydown', this.onActivity);
    window.addEventListener('scroll', this.onActivity);
  },
  destroyed() {
    window.removeEventListener('mousemove', this.onActivity);
    window.removeEventListener('keydown', this.onActivity);
    window.removeEventListener('scroll', this.onActivity);
  },
};
</script>
<style>
.router-style {
  text-decoration: none;
  color: white;
}
</style>

