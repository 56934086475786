<template>
    <v-container class="d-flex align-center" fluid>
        <v-row justify="center">
            <v-col class="d-flex justify-center" cols="12" sm="8" md="4">
                <v-card width="600">
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field label="Email" v-model="email" :rules="emailRules" required></v-text-field>
                            <v-text-field label="Password" type="password" v-model="password" :rules="passwordRules" required></v-text-field>
                            <v-btn color="primary" @click="login" :disabled="!formIsValid">Login</v-btn>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$router.push('/register')">Register</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            email: '',
            password: '',
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            passwordRules: [(v) => !!v || 'Password is required'],
        };
    },
    computed: {
        formIsValid() {
            return this.email && this.password;
        },
    },
    methods: {
        async login() {
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/login`, {
                    email: this.email,
                    password: this.password,
                });
                console.log(response.data);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('uuid', response.data.uuid);
                this.$router.push('/dashboard');
            } catch (error) {
                console.error(error.response.data);
            }
        },
    },
    created() {
        localStorage.removeItem('token');
        localStorage.removeItem('uuid');
    }
};
</script>
