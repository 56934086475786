<template>
    <v-container class="d-flex align-center" fluid>
        <v-row justify="center">
            <v-col class="d-flex justify-center" cols="12" sm="8" md="4">
                <v-card width="600" d-flex>
                    <v-toolbar color="primary" dark>
                        <v-btn icon @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title>Register</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form @submit.prevent="register">
                            <v-alert
                                v-if="errorMessage"
                                dismissible
                                color="error"
                                icon="mdi-alert-circle-outline"
                                border="left"
                                elevation="2"
                                class="my-4"
                            >
                                {{ errorMessage }}
                            </v-alert>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="name"
                                            label="Name"
                                            :rules="nameRules"
                                            required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="email"
                                            label="Email"
                                            :rules="emailRules"
                                            required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="password"
                                            label="Password"
                                            :rules="passwordRules"
                                            type="password"
                                            required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row align-items="end">
                                <v-col cols="8">
                                    <v-text-field
                                            v-model="emailCode"
                                            label="Email Code"
                                            :rules="emailCodeRules"
                                            half-width
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn color="primary" @click="sendCode" :disabled="!email">Send Code</v-btn>
                                </v-col>
                            </v-row>
                            <v-btn color="primary" type="submit" :disabled="!formIsValid">Register</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import router from "@/router";

export default {
    data() {
        return {
            email: '',
            password: '',
            name: '',
            emailCode: '',
            nameRules: [(v) => !!v || 'Name is required'],
            emailRules: [
                (v) => !!v || 'Email is required',
                (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            passwordRules: [(v) => !!v || 'Password is required'],
            emailCodeRules: [(v) => !!v || 'Email Code is required'],
            errorMessage: '',
        };
    },
    computed: {
        formIsValid() {
            return (
                this.name &&
                this.email &&
                this.password &&
                this.emailCode
            );
        },
    },
    methods: {
        async register() {
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/register`, {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    code: this.emailCode,
                });
                console.log(response.data)
                await router.push('/login');
            } catch (error) {
                console.error(error.response.data);
                this.errorMessage = error.response.data.message;
                setTimeout(() => {
                    this.errorMessage = '';
                }, 10000);
            }
        },
        async sendCode() {
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/send-code`, {
                    email: this.email,
                });
                console.log(response.data);
                // do something with the response data
            } catch (error) {
                this.errorMessage = error.response.data.message;
                setTimeout(() => {
                    this.errorMessage = '';
                }, 10000);
            }
        },
    },
};
</script>
